import DropdownComp from "../../components/dropdown";
import Header from "../../components/header";
import * as S from "./style";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_BRANDS } from "../selectBrand/queries";
import { toast } from "react-toastify";
import Button from "../../components/button";
import TextInput from "../../components/textInput";
import { Link } from "react-router-dom";
import { useFilePicker } from "react-sage";
import PacmanLoader from "../../components/pacmanLoader";
import useAuth from "../../hooks/useAuth/useAuth";

import InfiniteScrollDropdown from "../../components/infiniteScrollDropdown";

export default function AddContentComponent(props) {
  const { loading, data, error, fetchMore } = useQuery(GET_BRANDS);
  const [copied, setCopied] = useState(false);
  const [taskData, setTaskData] = useState();
  const [status, setStatus] = useState(false);
  const [brands, setBrands] = useState([]);

  const { files, onClick, HiddenFileInput, errors } = useFilePicker();
  const { authLoading, authError } = useAuth();

  useEffect(() => {
    let temp = data?.brands?.edges?.map((e) => ({
      value: e.node.id,
      label: e.node.brandName,
    }));
    temp && props.setBrandsList([...temp]);
    temp && setBrands([...temp]);
  }, [data]);

  const onChange = (e) => {
    props.SetBrand(e);
    localStorage.setItem("activeBrandId", e.value);

    data?.brands?.edges?.map((brand) => {
      if (brand.node.id == e.value) {
        brand?.node?.store?.id &&
          localStorage.setItem("activeStoreId", brand?.node?.store?.id);
      }
    });
  };

  const uploadContent = async (fileKey) => {
    let data = {
      fileKey: fileKey,
      brandId: props.activeBrand.value,
    };
    let resp = await props.contentUpload(data);
    if (resp.success) {
      //console.log(data, resp);

      setTimeout(async () => {
        let interval;
        interval = setInterval(async () => {
          let taskStatus = await props.getTaskStatus({
            taskId: resp.data.task_id,
          });
          //console.log(taskStatus, "taskStatus");
          if (taskStatus.success && taskStatus.data.status == "SUCCESS") {
            toast("Content uploaded successfully!");
            setTaskData(JSON.parse(taskStatus.data.result));
            clearInterval(interval);
          } else {
            toast("Something went wrong!");
          }
          setStatus(false);
        }, 2000);
      }, 2000);
    } else {
      toast("Something went wrong!");
      setStatus(false);
    }
  };

  const makeZip = (files) => {
    const zip = require("jszip")();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i]);
    }
    //console.log(zip.generateAsync({ type: "blob" }));
    zip.generateAsync({ type: "blob" }).then((content) => {
      //console.log(content);
      // saveAs(content, "example.zip");
      props.getPresignedUrl({extension: "zip"}).then((res) => {
        //console.log(res, "presigned url");
        if (!res.success) {
          toast("Something went wrong!");
          setStatus(false);
        }

        if (res.success) {
          let data = {
            url: res.data.url,
            file: content,
          };
          props.putZipFileToAws(data).then((resp) => {
            //console.log(resp, "putFileToAws");
            if (res.success) {
              uploadContent(res.data.file_key);
              setStatus("processing");
            } else {
              toast("Something went wrong!");
            }
          });
        }
      });
    });
  };

  useEffect(() => {
    //console.log(files);
    if (files.length) {
      setTaskData(undefined);
      setStatus("uploading");
      //console.log(files, errors);
      // uploadContent(files[0])
      makeZip(files);
    }
  }, [files]);

  if (authLoading) return <PacmanLoader />;
  if (authError) return <div>Error!</div>;

  return (
    <>
      <Header />
      <S.Wrapper>
        <S.Flex>
          <InfiniteScrollDropdown
            width="100%"
            placeholder="Select Brand"
            onChange={onChange}
            data={[...brands]}
            defaultValue={{
              value: localStorage.getItem("activeBrandId"),
              label: localStorage.getItem("activeBrandName"),
            }}
            label="label"
            value="value"
            fetchMore={
              (//console.log("fetching more..."),
              data?.brands?.pageInfo?.hasNextPage &&
                fetchMore({
                  variables: {
                    endCursor: data?.brands?.pageInfo?.endCursor,
                  },
                }))
            }
          />
        </S.Flex>
        {taskData && (
          <S.Table>
            <tbody>
              <tr>
                <th>S.No</th>
                <th>Content</th>
                <th>Link</th>
                <th>Status</th>
              </tr>
              {taskData.success.map((e, i) => (
                <tr key={e.media_url}>
                  <td>{i + 1}</td>
                  <td className="img_cont">
                    {e.media_type == "IMAGE" ? (
                      <img className="img" src={e.media_url} />
                    ) : (
                      <video>
                        <source src={e.media_url} />
                      </video>
                    )}
                  </td>
                  <td
                    className="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(e.media_url);
                    }}
                  >
                    <>
                      {e.media_url}{" "}
                      <span>
                        <img src="/assets/images/copy.png" />
                      </span>
                    </>
                  </td>
                  <td className="scs">SUCCESS</td>
                </tr>
              ))}
              {taskData.fail.map((e, i) => (
                <tr key={e.media_url}>
                  <td>{taskData.success.length + i + 1}</td>
                  <td className="img_cont">
                    {e.media_type == "IMAGE" ? (
                      <img className="img" src={e.media_url} />
                    ) : (
                      <video>
                        <source src={e.media_url} />
                      </video>
                    )}
                  </td>
                  <td
                    className="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(e.media_url);
                    }}
                  >
                    <>
                      {e.media_url}{" "}
                      <span>
                        <img src="/assets/images/copy.png" />
                      </span>
                    </>
                  </td>
                  <td className="scs">SUCCESS</td>
                </tr>
              ))}
            </tbody>
          </S.Table>
        )}

        {status !== false && (
          <p style={{ textAlign: "center" }}>
            <img
              style={{ width: "50px" }}
              src="/assets/images/loader-gif.gif"
            />
            <br /> {status == "uploading" ? "Uploading..." : "Processing..."}
          </p>
        )}

        <S.Flex>
          <Link
            to={props.activeBrand ? "/content/home" : ""}
            onClick={() =>
              !props.activeBrand && toast("Please select a brand!")
            }
          >
            <Button
              style={{
                padding: "2px 7px",
                fontSize: "0.8rem",
                minWidth: "80px",
              }}
            >
              GO TO HOME
            </Button>
          </Link>
        </S.Flex>

        {props.activeBrand ? (
          <S.BrowseFile
            onClick={() => {
              onClick();
              //console.log("triggred");
            }}
          >
            <h2>Browse your file here...</h2>
            <p>Supports: all files</p>
          </S.BrowseFile>
        ) : (
          <S.BrowseFile onClick={() => toast("Please select a brand!")}>
            <h2>Browse your file here...</h2>
            <p>Supports: all files</p>
            <p>
              <b>Please select Brand!</b>
            </p>
          </S.BrowseFile>
        )}
        <HiddenFileInput multiple />
      </S.Wrapper>
    </>
  );
}
