import Searchable from "react-searchable-dropdown";
import * as S from "./style";

const DropdownComp = (props) => {
  const options = [...props.options];
  return (
    <S.Div style={{ width: props?.width ? props.width : "auto" }}>
      <Searchable
        className="myControlClassName"
        options={options}
        value={props?.value ?? props?.default ?? options[0] ?? null}
        onSelect={(value) => {
          props.onChange(value);
          //console.log(value)
        }}
        placeholder={props.placeholder}
      />
    </S.Div>
  );
};

export default DropdownComp;
