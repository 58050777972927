import styled from "styled-components";

export const Div = styled.div`
  font-family: Roboto !important;
  margin-right: 1px;
  .searchable__controls,
  .searchable__list,
  .searchable__list__item {
    background-color: #fff !important;
    color: #000;
  }
  .searchable__controls {
    border: 1px solid #dadada;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .searchable__controls__arrow {
    // background-color: #000;
  }
  input {
    color: #000 !important;
    ::placeholder {
      color: #797878 !important;
    }
  }
  .searchable__controls input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7rem;
    padding: 8px 10px 8px 5px;
  }
  .searchable__controls__arrow {
    width: 17px;
    border-radius: 0 5px 5px 0;
    padding-right: 2px;
    svg {
      width: 10px;
      fill: #000;
    }
  }
  .myControlClassName {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7rem;
    padding: 3px 1px 3px 1px;
    background: #fff;
  }
  .myPlaceholderClassName {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7rem;
    padding: 4px 10px 4px 1px;
  }
`;
