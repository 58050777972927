import { useState } from "react";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";

const LazyImg = (props) => {
  const [image, setImage] = useState();
  return (
    <>
      {!image && (
        <div
          style={{
            height: "200px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p></p>
        </div>
      )}
      <img {...props} onLoad={() => setImage("loaded")} />
    </>
  );
};

export default LazyImg;
