import { useRef, useState, useCallback, useEffect } from "react";
import { ReactSVG } from "react-svg";
import DropdownComp from "../../../../components/dropdown";
import * as S from "./style";
import LazyImg from "../../../../components/lazyImg";
import { toast } from "react-toastify";

const ContentCard = (props) => {
  const [activeContentType, setActiveContentType] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isReelTagged, setIsReeltagged] = useState(false);

  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    if (vidRef.current.paused) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
    handleZoomChange();
  };

  const handleZoomChange = () => {
    setIsZoomed(!isZoomed);
  };

  const untagContent = (data) => {
    props?.postUnTagContent({
      ...data,
      taggedDataList: props?.taggedDataList,
    });
  };

  const toggleModal = (type) => {
    if (activeContentType !== null) {
      props?.toggleModal(type);
    } else {
      // alert("Please select a content type first.");
      toast("Please select a content type first!");
    }
  };

  const trashContent = async (id) => {
    //console.log("clicked");
    let data = {
      ids: [id],
      contentList: [...props.contentList],
      hasMore: props.hasMore,
    };
    await props.postTrashContent(data);
  };

  const storeId = localStorage.getItem("activeStoreId");
  const brandId = localStorage.getItem("activeBrandId");
  const brandName = localStorage.getItem("activeBrandName");

  const toggleBrandContent = (flag) => {
    if (flag == "UNTAG") {
      untagBrandContent();
    } else {
      tagBrandContent();
    }
  };

  const tagBrandContent = async () => {
    if (!["REEL", "SIZE_CHART", "STORY"].includes(activeContentType)) {
      return toast.warn('Invalid content type selected for tag type brand.')
    }
    let data = {
      products: [
        {
          id: props.id,
          content_type: activeContentType,
        },
      ],
      zaamo_id: brandId,
      tagType: "BRAND",
      dataToAdd: {
        id: props.id,
        content_type: activeContentType,
      },
      taggedDataList: props?.taggedDataList
    };
    let resp = await props.postTagContent(data);
    //console.log(resp);
    if (resp.success) {
      setIsReeltagged(true);
    }
  };

  const untagBrandContent = async () => {
    let data = {
      content: [
        {
          content_id: props.id,
          zaamo_id: brandId,
        },
      ],
      taggedDataList: props?.taggedDataList,
    };
    let resp = await props.postUntagBrandContent(data);
    if (resp.success) {
      setIsReeltagged(false);
    }
  };

  const activeBrandId = localStorage.getItem("activeBrandId");
  useEffect(() => {
    props?.tagged_brands.map((e) => {
      if (e.zaamo_id == activeBrandId) {
        setIsReeltagged(true);
      }
    });
  }, [props?.tagged_brands]);

  return (
    <S.ContentCard>
      <S.ProductImage>
        {props?.activeContentTypes?.contentSource !== "TRASH_CONTENT" && (
          <S.Cross style={{ zIndex: 1 }} onClick={() => trashContent(props.id)}>
            <ReactSVG src="/assets/images/cross-trash.svg" />
          </S.Cross>
        )}
        {props?.media_type == "VIDEO" ? (
          <div
            className={isZoomed ? "zoomedContainer" : ""}
            onClick={handlePlayVideo}
          >
            {isZoomed && <span className="closeZoom">&nbsp;X&nbsp;</span>}
            <video className="zoomedMedia" ref={vidRef} width="100%">
              <source src={props?.media_url} type="video/mp4" />
            </video>
          </div>
        ) : (
          <div
            className={isZoomed ? "zoomedContainer" : ""}
            onClick={handleZoomChange}
          >
            {isZoomed && <span className="closeZoom">&nbsp;X&nbsp;</span>}
            <div className="zoomedMedia" style={{ minWidth: "200px" }}>
              <LazyImg width="100%" src={props?.media_url} alt="img" />
            </div>
          </div>
        )}
        <S.ProductOverviewWrap>
          <ul>
            <li>
              <ReactSVG
                style={{ filter: "drop-shadow(0px 0px 4px #000)" }}
                src="/assets/images/heart.svg"
              />
              <span>{props?.likes}</span>
            </li>
            <li>
              <ReactSVG
                style={{ filter: "drop-shadow(0px 0px 4px #000)" }}
                src="/assets/images/comment.svg"
              />
              <span>{props?.comments}</span>
            </li>
            <li>
              <ReactSVG
                style={{ filter: "drop-shadow(0px 0px 4px #000)" }}
                src="/assets/images/share.svg"
              />
              <span>{props?.followers}</span>
            </li>
          </ul>
        </S.ProductOverviewWrap>
      </S.ProductImage>
      <S.Flex>
        <S.FlexScroll>
          <DropdownComp
            width="130px"
            placeholder="Content Type"
            options={
              props?.contentFilters !== null
                ? props?.contentFilters?.content_type
                : []
            }
            onChange={(e) => setActiveContentType(e)}
          />
          {/* <S.ButtonAdd>
            <span>+Add Product</span>
          </S.ButtonAdd> */}
          <S.ButtonAdd
            onClick={
              storeId
                ? () =>
                    toggleModal({
                      type: "COLLECTION",
                      id: props?.id,
                      activeContentType: activeContentType,
                    })
                : () => toast("No collection found for this brand!")
            }
          >
            <span>+Tag Collections</span>
          </S.ButtonAdd>
          <S.ButtonAdd
            onClick={() =>
              toggleModal({
                type: "PRODUCT",
                id: props?.id,
                activeContentType: activeContentType,
              })
            }
          >
            <span>+Tag Products</span>
          </S.ButtonAdd>
          <S.ButtonAdd onClick={() => toggleBrandContent("TAG")}>
            <span>+ Tag Brand Content</span>
          </S.ButtonAdd>
        </S.FlexScroll>
      </S.Flex>

      {props?.taggedDataList[`${props?.id}`] && (
        <>
          {props?.taggedDataList[`${props?.id}`]["PRODUCT"].length ? (
            <S.FlexScroll
              style={{ justifyContent: "flex-start", overflow: "hidden" }}
            >
              <label className="label-sm">Tagged Products: </label>
              {[...props?.taggedDataList[`${props?.id}`]["PRODUCT"]].map(
                (e, i) => (
                  <S.CategoryGroup key={e.id}>
                    <ReactSVG
                      onClick={() =>
                        untagContent({
                          products: [
                            { content_id: props?.id, product_id: e.id },
                          ],
                          untagType: "PRODUCT",
                        })
                      }
                      src="/assets/images/cross.svg"
                    />
                    <a target="_blank" href={`${process.env.REACT_APP_HOME_URL}/${e?.slug}`}>{e.name}</a>
                  </S.CategoryGroup>
                )
              )}
            </S.FlexScroll>
          ) : null}
          {props?.taggedDataList[`${props?.id}`]["COLLECTION"].length ? (
            <S.FlexScroll>
              <label className="label-sm">Tagged Collections: </label>
              {[...props?.taggedDataList[`${props?.id}`]["COLLECTION"]].map(
                (e, i) => (
                  <S.CategoryGroup key={e.id}>
                    <ReactSVG
                      onClick={() =>
                        untagContent({
                          products: [
                            { content_id: props?.id, collection_id: e.id },
                          ],
                          untagType: "COLLECTION",
                        })
                      }
                      src="/assets/images/cross.svg"
                    />
                    <span>{e.name}</span>
                  </S.CategoryGroup>
                )
              )}
            </S.FlexScroll>
          ) : null}
          {props?.taggedDataList[`${props?.id}`]["BRAND"].length ? (
            <S.FlexScroll>
              <label className="label-sm">Tagged Brands: </label>
              {[...props?.taggedDataList[`${props?.id}`]["BRAND"]].length && (
                <S.CategoryGroup>
                  <ReactSVG
                    onClick={() => untagBrandContent()}
                    src="/assets/images/cross.svg"
                  />
                  <span>{brandName}</span>
                </S.CategoryGroup>
              )}
            </S.FlexScroll>
          ) : null}
        </>
      )}
      {props.caption && <S.ContentWrapper>{props.caption}</S.ContentWrapper>}
    </S.ContentCard>
  );
};

export default ContentCard;
