import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useLocation, useHistory  } from "react-router-dom";
import { TOKEN_VERIFY } from "./mutation";

const useAuth = () => {
  const history = useHistory();
  const location = useLocation();
  const [tokenVerify, { loading, error }] = useMutation(TOKEN_VERIFY);

  useEffect(() => {
    const localStorageToken = localStorage.getItem("token");
    const mobileNo = localStorage.getItem("tagging_pannel_mobileNo");
    if (localStorageToken && mobileNo) {
      const main = async () => {
        const { data } = await tokenVerify({
          variables: {
            token: localStorageToken,
          },
        });
        const isValid = data.tokenVerify.isValid;
        if (isValid) {
          if (location.pathname === "/") history.push("/home");
        } else {
          history.push("/");
        }
      };
      main();
    } else {
      history.push("/");
    }
  }, []);

  return { authLoading: loading, authError: error };
};

export default useAuth;
