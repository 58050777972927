import { toast } from "react-toastify";
import { ServiceEnum, urlFor } from "../../network/Urls";
import { setTaggedData } from "./state";

const SERVICE_TOKEN = "2900ba48-85f6-4929-b19d-0c0da14dbc14";

export function postTagContent(data) {
  //console.log(data);
  return async (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Service-Token", SERVICE_TOKEN);
    myHeaders.append("Content-Type", "application/json");
    let activeBrandId = localStorage.getItem("activeBrandId");

    var raw = JSON.stringify({
      data: [
        // {
        //   id: 41,
        //   product_id: "UHJvZHVjdDoxMTQ0",
        //   sku_id: "UHJvZHVjdFZhcmlhbnQ6MjI5MQ==",
        //   content_type: "SEARCH_IMAGE",
        // },
        ...data.products,
      ],
      zaamo_id: activeBrandId, //"QnJhbmQ6Ng=="
      user_type: "BRAND",
      tag_type: data.tagType,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`${urlFor(ServiceEnum.postTagContent)}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          alert("Something went wrong!");
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then((result) => {
        //console.log(result, data);
        // updating data in store
        let temp = { ...data.taggedDataList };
        temp[`${data.products[0].id}`][data.tagType].push(data.dataToAdd);
        //console.log("TAGGED_DATA2", temp);
        dispatch({ type: "TAGGED_DATA", payload: temp });

        toast.success('Tagged content successfully!');
        return { success: true, data: result };
      })
      .catch((error) => {
        //console.log("error", error);
        return { success: false, msg: error };
      });
  };
}

export function postUnTagContent(data) {
  return async (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Service-Token", SERVICE_TOKEN);
    myHeaders.append("Content-Type", "application/json");
    //console.log(data, "data post");
    var raw = JSON.stringify({
      data: [...data.products],
      untag_type: data.untagType,
    }); // == "REEL" ? "BRAND" : data.untagType

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`${urlFor(ServiceEnum.postUnTagContent)}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          toast("Something went wrong!");
          // throw new Error("HTTP status " + response.status);
          return { success: false };
        }
        return response.json();
      })
      .then((result) => {
        //console.log(result, data);

        // updating data in store
        let temp = { ...data.taggedDataList };
        if (data.untagType == "PRODUCT") {
          for (let i in temp[`${data.products[0].content_id}`][
            data.untagType
          ]) {
            if (
              temp[`${data.products[0].content_id}`][data.untagType][i].id ==
              data.products[0].product_id
            ) {
              temp[`${data.products[0].content_id}`][data.untagType].splice(
                i,
                1
              );
            }
          }
        } else if (data.untagType == "COLLECTION") {
          for (let i in temp[`${data.products[0].content_id}`][
            data.untagType
          ]) {
            if (
              temp[`${data.products[0].content_id}`][data.untagType][i].id ==
              data.products[0].collection_id
            ) {
              temp[`${data.products[0].content_id}`][data.untagType].splice(
                i,
                1
              );
            }
          }
        }

        //console.log("TAGGED_DATA3", temp);
        dispatch({ type: "TAGGED_DATA", payload: temp });
        return { success: true };
      })
      .catch((error) => {
        //console.log("error", error);
        return { success: false, msg: error };
      });
  };
}

export function postTrashContent(data) {
  return async (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Service-Token", SERVICE_TOKEN);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ids: [...data.ids],
      is_trash: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_CONTENT_API_BASE_URL}/streaming/api/content/trash/`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          alert("Something went wrong!");
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then((result) => {
        //console.log(result);
        let tempData = [...data.contentList];
        for (let i in tempData) {
          if (tempData[i].id == data.ids[0]) {
            tempData.splice(i, 1);
          }
        }
        dispatch({
          type: "CONTENT_LIST_REPLACE",
          payload: { data: tempData, hasMore: data.hasMore },
        });
      })
      .catch((error) => {
        //console.log("error", error);
        return { success: false, msg: error };
      });
  };
}

export function contentUpload(data) {
  return async () => {
    //console.log("init", data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      zaamo_id: data.brandId,
      user_type: "BRAND",
      method: "FILE_KEY",
      value: data.fileKey,
      tag_product: false,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let req = await fetch(
      `${process.env.REACT_APP_CONTENT_API_BASE_URL}/engine/content/upload`,
      requestOptions
    );
    if (!req.ok) {
      alert("Something went wrong!");
      throw new Error("HTTP status " + req.status);
    }
    let response = await req.json();

    //console.log(response, "contentUpload");
    return { success: true, data: response };
  };
}

export function putZipFileToAws(data) {
  //console.log(data);
  return async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/zip");

    var file = data.file;

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    return fetch(data.url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return { success: true };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        //console.log(error, "error");
        return { success: false, msg: error };
      });
  };
}

export function postUntagBrandContent(data) {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Service-token", SERVICE_TOKEN);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      data: [...data.content],
      untag_type: "BRAND",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(urlFor(ServiceEnum.postUnTagContent), requestOptions)
      .then((response) => {
        if (!response.ok) {
          return { success: false };
        }
        response.json();
      })
      .then((result) => {
        //console.log(result);
        let temp = { ...data.taggedDataList };
        
        temp[`${data.content[0].content_id}`]["BRAND"] = []
        //console.log("TAGGED_DATA3", temp);
        dispatch({ type: "TAGGED_DATA", payload: temp });
        toast.success('Untagged content successfully!')
        return { success: true, data: result };
      })
      .catch((error) => {
        //console.log("error", error)
        toast.error('Something went wrong!')
      });
  };
}
