import styled from "styled-components";

export const ScrollY = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; 
  height: 100%;
  overflow-y: scroll;
  max-height: 70vh;
`;
