import { gql } from "@apollo/client";

export const GET_BRANDS = gql`
  query GetBrands($endCursor: String){
    brands(
      first: 50
      sortBy: { direction: ASC, field: BRAND_NAME }
      after: $endCursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          brandName
          store {
            id
            storeName
          }
        }
      }
    }
  }
`;

// export const GET_BRANDS = gql`
//   query GetBrands($mobileNo: String!) {
//     userByMobile(mobileNo: $mobileNo) {
//       id
//       isActive
//       authorisedBrands {
//         id
//         brandName
//       }
//     }
//   }
// `;