const ACTIVE_HOST = process.env.REACT_APP_CONTENT_API_BASE_URL;

export const ServiceEnum = {
     getContentFilters: 'streaming/api/get/content/filters/',
     getTagContent: 'streaming/api/tag/content/',
     getContentList: 'streaming/api/content/',
     getProducts: 'streaming/api/get/products',
     getCollections: 'streaming/api/get/collections',
     getPresignedUrl: 'streaming/api/presigned/url/toupload/',
     getFileKey: 'streaming/api/presigned/url/toupload/',
     getTaskStatus: 'engine/task/status',

     postTagContent: 'streaming/api/tag/content/',
     postUnTagContent: 'streaming/api/untag/content/',
     userList: (id) => `user/list/${id}`,

};

export const urlFor = (service) => {
     if (service) {
          return `${ACTIVE_HOST}/${service}`;
     }
     return undefined;
};