import styled from "styled-components";

export const Nav = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 0.5rem;
  position: relative;
`;

export const Logo = styled.div`
  color: #fff;
  font-family: "Merriweather", serif;
  font-size: 1.4rem;
`;

export const Button = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  button {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.79);
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 30px;
    color: #000;
    padding: 2px 5px;
    margin: auto;
    border: hidden;
    display: block;
    cursor: pointer;
    text-decoration: none !important;
  }
`;
