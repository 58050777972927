import * as S from "./styles";
import Select, { components } from "react-select";
import { Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function InfiniteScrollDropdown(props) {
  const options = props.data.map((e) => ({
    label: e[props.label],
    value: e[props.value],
  }));

  return (
    <S.Dropdown style={props.style}>
      <Select
        options={options}
        onMenuScrollToBottom={props.fetchMore}
        onChange={props.onChange}
        isClearable={props.isClearable}
        placeholder={props.placeholder ?? "Select..."}
        value={props?.defaultValue}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 2 }) }}
      />
    </S.Dropdown>
  );
}
