import { Link } from "react-router-dom";
import * as S from "./style";

const Header = ({ title }) => {
  return (
    <S.Nav>
      <S.Div>
        <S.Logo>ZAAMO</S.Logo>
        <S.Button>
          <Link to="/add/content">
            <button>Upload Content</button>
          </Link>
        </S.Button>
      </S.Div>
    </S.Nav>
  );
};

export default Header;
