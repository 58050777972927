import DropdownComp from "../../components/dropdown";
import Header from "../../components/header";
import * as S from "./style";
import Button from "../../components/button";
import { useQuery } from "@apollo/client";
import { GET_BRANDS } from "./queries";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScrollDropdown from "../../components/infiniteScrollDropdown";
import useAuth from "../../hooks/useAuth/useAuth";
import PacmanLoader from "../../components/pacmanLoader";

export default function SelectBrandComponent(props) {
  const { loading, data, error, fetchMore } = useQuery(GET_BRANDS, {
    variables: {
      mobileNo: localStorage.getItem("tagging_pannel_mobileNo"),
    },
  });
  const [brands, setBrands] = useState([]);
  const [brandList, setBrandList] = useState({});

  const { authLoading, authError } = useAuth();

  useEffect(() => {
    let temp = data?.brands?.edges?.map((e) => ({
      value: e.node.id,
      label: e.node.brandName,
    }));
    temp && setBrands([...temp]);
  }, [data]);

  const onChange = (e) => {
    props.SetBrand(e);
    localStorage.setItem("activeBrandId", e.value);
    data?.brands?.edges.map((brand) => {
      if (brand.node.id == e.value) {
        brand?.node?.store?.id &&
          localStorage.setItem("activeStoreId", brand?.node?.store?.id);
        brand?.node?.brandName &&
          localStorage.setItem("activeBrandName", brand?.node?.brandName);
      }
    });
  };

  if (authLoading) return <PacmanLoader />;
  if (authError) return <div>Error!</div>;

  return (
    <>
      <Header />
      <S.Wrapper>
        <S.Flex>
          <InfiniteScrollDropdown
            width="100%"
            placeholder="Select Brand"
            onChange={onChange}
            data={[...brands]}
            defaultValue={{
              value: localStorage.getItem("activeBrandId"),
              label: localStorage.getItem("activeBrandName"),
            }}
            label="label"
            value="value"
            fetchMore={
              (//console.log("fetching more..."),
              data?.brands?.pageInfo?.hasNextPage &&
                fetchMore({
                  variables: {
                    endCursor: data?.brands?.pageInfo?.endCursor,
                  },
                }))
            }
          />
        </S.Flex>
        <S.Flex style={{ marginTop: "20vh" }}>
          <Link
            style={{ width: "50%", margin: "5px" }}
            onClick={() =>
              !props.activeBrand && toast("Please select an Brand!")
            }
            to={props.activeBrand ? "/content/home" : "/home"}
          >
            <Button style={{ width: "100%" }}>Next</Button>
          </Link>
          <Link
            style={{ width: "50%", margin: "5px" }}
            onClick={() =>
              !props.activeBrand && toast("Please select an Brand!")
            }
            to={props.activeBrand ? "/add/content" : "/home"}
          >
            <Button style={{ width: "100%" }}>Upload Content</Button>
          </Link>
        </S.Flex>
      </S.Wrapper>
    </>
  );
}
