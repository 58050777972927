import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 45px 15px 0 15px;
  max-width: 800px;
  margin: auto;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
`;

export const BrowseFile = styled.div`
  background: rgba(248, 250, 252, 0.24);
  border: 3px solid rgba(95, 97, 99, 0.44);
  box-sizing: border-box;
  border-radius: 15px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  h2 {
    font-size: 1.1rem;
    color: #000000;
    margin: 0;
  }
  p {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
  }
`;
// backgroud-image: ${({ bgImg }) => `url(${bgImg})`};

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 30px 0;
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    position: relative;
    .copy {
      position: absolute;
      right: 0;
      top: 0;
      background: #000;
      color: #fff;
    }
  }
  .scs {
    color: green;
    font-size: 0.8rem;
  }
  .fail {
    color: red;
    font-size: 0.8rem;
  }
  img, video {
    max-width: 100px;
  }
  .img_cont {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .copy {
    cursor: pointer;
    position: relative;
    span {
      position: absolute;
      top: 0;
      right: 0;
      background: #faf8f8;
      height:100%;
      padding: 0 5px;
      display: flex;
      align-items: center;
    }
    span:hover {
      background:#f1f1f1;
      transform-duration: 0.5s;
    }
  }
`;
