import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import ProductList from "../../../../components/productList";
import SearchInput from "../../../../components/search";
import { GET_SEARCHED_PRODUCTS } from "./queries";
import * as S from "./style";

const TagProducts = (props) => {
  const [data, setData] = useState([]);
  const activeBrandId = localStorage.getItem("activeBrandId");

  const [getSearchedProducts, { data: searchData, loading, error }] =
    useLazyQuery(GET_SEARCHED_PRODUCTS);

  useEffect(async () => {
    if (props.modalData.type == "PRODUCT") {
      await props?.parentProps?.getProducts({
        type: "PRODUCT",
        endCursor: undefined,
      });
      setData(props?.parentProps?.tagProducts?.data?.products);
    } else {
      await props?.parentProps?.getProducts({
        type: "COLLECTION",
        endCursor: undefined,
      });
      setData(props?.parentProps?.tagCollection?.data?.collections);
    }
  }, [props.modalData]);

  useEffect(() => {
    if (props.modalData.type == "PRODUCT") {
      setData(props?.parentProps?.tagProducts?.data?.products);
    } else {
      setData(props?.parentProps?.tagCollection?.data?.collections);
    }
  }, [
    props?.parentProps?.tagCollection?.data?.collections,
    props?.parentProps?.tagProducts?.data?.products,
    props?.modalData?.type,
  ]);

  useEffect(() => {
    console.log(
      searchData?.products?.edges?.map((e) => e.node),
      "--------"
    );
    setData(searchData?.products?.edges?.map((e) => e.node));
  }, [searchData]);

  const searchFn = (arr, query) => {
    if (query) {
      console.log(query);
      // return arr.filter((o) =>
      //   o.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      // );
      getSearchedProducts({
        variables: {
          searchString: query,
          endCursor: searchData?.data?.products?.pageInfo?.endCursor,
          brands: [activeBrandId]
        },
      });
      return;
    } else {
      return [...arr];
    }
  };

  const onChange = (e) => {
    let temp;
    if (props.modalData.type == "PRODUCT") {
      temp = searchFn(
        [...props?.parentProps?.tagProducts?.data?.products],
        e.target.value
      );
      setData(temp);
    } else {
      temp = searchFn(
        [...props?.parentProps?.tagCollection?.data?.collections],
        e.target.value
      );
    }
    setData(temp);
  };

  return (
    <S.Wrapper>
      <SearchInput onChange={onChange} />
      <S.ScrollY>
        {props.modalData.type == "PRODUCT" ? (
          <ProductList
            postUnTagContent={props.postUnTagContent}
            taggedDataList={props?.taggedDataList}
            postUnTagContent={props.postUnTagContent}
            taggedDataList={props?.taggedDataList}
            tagCollection={props?.parentProps?.tagCollection}
            tagProducts={props?.parentProps?.tagProducts}
            getProducts={props?.parentProps?.getProducts}
            type={props.modalData.type}
            products={data ? [...data] : []}
            {...props}
          />
        ) : (
          <ProductList
            postUnTagContent={props.postUnTagContent}
            taggedDataList={props?.taggedDataList}
            tagCollection={props?.parentProps?.tagCollection}
            tagProducts={props?.parentProps?.tagProducts}
            getProducts={props?.parentProps?.getProducts}
            type={props.modalData.type}
            products={data ? [...data] : []}
            {...props}
          />
        )}
      </S.ScrollY>
    </S.Wrapper>
  );
};

export default TagProducts;
