import PacmanLoader from "../../components/pacmanLoader";
import useAuth from "../../hooks/useAuth/useAuth";
import Otp from "./OtpVerification";
import SignIn from "./SignIn";
import useAuthVars from "./useAuth";

const Auth = () => {
  const {
    phone,
    setPhone,
    invalidOtp,
    setInvalidOtp,
    otp,
    setOtp,
    isOtpGeneratedSuccessfully,
    setIsOtpGeneratedSuccessfully,
    tokenCreate,
    generateOtp,
    verifyOtp,
    router,
    handleChange,
    handleSubmit,
    regenerateOtp,
    handleOtpSubmit,
    loading,
    error,
  } = useAuthVars();

  const { authLoading, authError } = useAuth();

  if (loading || authLoading) return <PacmanLoader />;

  if (error || authError) return <div>Oops! something went wrong...</div>;

  return (
    <>
      {isOtpGeneratedSuccessfully ? (
        <Otp
          {...{
            handleOtpSubmit,
            phone,
            otp,
            setOtp,
            regenerateOtp,
            invalidOtp,
            setInvalidOtp,
          }}
        />
      ) : (
        <SignIn {...{ handleSubmit, phone, handleChange }} />
      )}
    </>
  );
};

export default Auth;
