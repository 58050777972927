import { gql } from "@apollo/client";

export const GENERATE_OTP = gql`
  mutation GenerateOtp($mobileNo: String!) {
    generateOtp(mobileNo: $mobileNo) {
      success
      accountErrors {
        message
      }
    }
  }
`;

export const VALIDATE_OTP = gql`
  mutation VerifyOtp($mobileNo: String!, $otp: Int!) {
    verifyOtp(mobileNo: $mobileNo, otp: $otp) {
      success
      accountErrors {
        message
      }
    }
  }
`;
export const TOKEN_CREATE = gql`
  mutation TokenCreate($mobileNo: String!) {
    tokenCreate(mobileNo: $mobileNo) {
      token
      refreshToken
      user {
        id
        isActive
        userId
        influencer {
          id
        }
      }
    }
  }
`;
