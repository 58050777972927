import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

const httpLink = new createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_BASE_URL,
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        merge: true,
        fields: {
          brands: relayStylePagination(),
        },
      },
    },
  }),
  link: ApolloLink.from([httpLink]),
});
