import styled from "styled-components";

export const Dropdown = styled.div`
  padding: 0;
  background: #fff;
  width: 90%;
  margin: auto;
  max-width: 300px;

`;
