import Button from "../../components/button";
import * as S from "./styles";

const Otp = ({
    handleOtpSubmit,
    phone,
    otp,
    setOtp,
    regenerateOtp,
    invalidOtp,
    setInvalidOtp,
  }) => {
  return (
    <S.Wrapper>
      <S.PhoneNumber>OTP Verification</S.PhoneNumber>
      <S.TextDiv>
        Enter the OTP sent to <span>{"+91" + phone}</span>.
      </S.TextDiv>
      <S.FormStyled>
        <form onSubmit={handleOtpSubmit}>
          <div id="divOuter">
            <div id="divInner">
              <input
                autoFocus
                id="partitioned"
                type="text"
                autoComplete="one-time-code"
                maxLength={4}
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
            </div>
          </div>
          <S.BottomTextDiv>
            Didn't receive the OTP?{" "}
            <button type="button" onClick={() => regenerateOtp()}>
              RESEND OTP
            </button>
          </S.BottomTextDiv>
          <Button type="submit" className="form-field" fullWidth>
            VERIFY & PROCEED
          </Button>
        </form>
      </S.FormStyled>
    </S.Wrapper>
  );
};

export default Otp;
